import React from 'react'

import HeadingLink from './heading-link'

const Header = () =>
  <div className="container">
    <HeadingLink to='/'>mayask</HeadingLink>
    <div className="subtitle">photography</div>

    <style jsx>{`
      .container {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #111;
        color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 1rem 0;
      }

      .subtitle {
        font-weight: 300;
        font-size: .55rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: gray;
      }
    `}
    </style>
  </div>

export default Header
