import React from 'react'

const HeadingLink = ({ to, children }) => 
  <span className='container'>
    <a className='title' href={to}>{children}</a>

    <style jsx>{`
      .container {
        margin: .2rem 0;
      }

      .title {
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        font-size: .8rem;
        padding: .1rem .5rem;
        letter-spacing: 1px;
        text-decoration: none;
      }
      .title:hover {
        text-decoration: underline;
      }
    `}
    </style>
  </span>

export default HeadingLink
