import React from 'react'

const NavLink = ({ to, active, children }) => 
  <span className='container'>
    <a className='title' href={to}>{children}</a>

    <style jsx>{`
      .container {
        margin: .5rem;
      }

      .title {
        ${active ? `
          border-width: 2px;
          border-style: solid;
          border-color: #fff;
        ` : ''}
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        font-size: .7rem;
        text-decoration: none;
        padding: .1rem .5rem;
        letter-spacing: 1px;
      }
    `}
    </style>
  </span>

const Footer = ({ activePage }) =>
  <div className='container'>
    <NavLink active={activePage === 'Albums'} to=''>Albums</NavLink>
    <NavLink active={activePage === 'Blog'} to='/blog'>Blog</NavLink>
    <NavLink active={activePage === 'Contact'} to='/contact'>Contact</NavLink>

    <style jsx>{`
      .container {
        background-color: #111;
        color: #fff;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    `}</style>
  </div>

export default Footer
