import React from 'react'
import { Flex, Box } from 'rebass'

import Header from './header'
import Footer from './footer'


const Layout = ({ activePage, children }) =>
  <Flex my='6.2rem' alignItems='center' flexDirection='column'>
    <Box />
    <Box width={['95%', '90%', '80%', '55rem']}>
      {children}
    </Box>
    <Box />
    <Header />
    <Footer activePage={activePage} />

    <style jsx>{`
      :global(body) {
        background-color: black;
      }

      .children {

      }
    `}
    </style>
  </Flex>

export default Layout
